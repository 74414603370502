import React from 'react';
import PropTypes from 'prop-types';
import Img from 'gatsby-image';
//

const ThumbGrid = ({ images, handleOpen, classes }) => {
  return images.map((image, i) => (
    <button key={i} onClick={handleOpen(i)} className="btn">
      <Img fluid={{ ...image.image.asset.fluid, aspectRatio: 1.4 }} />
    </button>
  ));
};

ThumbGrid.propTypes = {
  classes: PropTypes.object,
  images: PropTypes.array,
};

export default ThumbGrid;
