import React, { useState } from 'react';
import { useStaticQuery, graphql, Link } from 'gatsby';
//
import './gallery.scss';
//
import { Layout } from '../components';
import LightBox from '../components/LightBox';
import ThumbGrid from '../components/Thumbnails';

const Gallery = () => {
  const [showLightbox, setShowLightbox] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const [showLightboxServiceCenter, setShowLightboxServiceCenter] = useState(false);
  const [selectedImageServiceCenter, setSelectedImageServiceCenter] = useState(null);

  const data = useStaticQuery(graphql`
    query {
      allSanityGallery {
        nodes {
          images {
            image {
              asset {
                fluid(maxWidth: 600) {
                  srcSetWebp
                  srcSet
                  srcWebp
                  src
                }
              }
            }
          }
        }
      }
    }
  `);
  // Super store center handlers

  const handleOpen = i => e => {
    console.log(i);
    setShowLightbox(true);
    setSelectedImage(i);
  };
  const handleClose = () => {
    setShowLightbox(false);
    setSelectedImage(null);
  };
  const handlePrevRequest = (i, length) => e => {
    setSelectedImage((i - 1 + length) % length);
  };
  const handleNextRequest = (i, length) => e => {
    setSelectedImage((i + 1) % length);
  };

  // Service center handlers

  const handleOpenServiceCenter = i => e => {
    console.log(i);
    setShowLightboxServiceCenter(true);
    setSelectedImageServiceCenter(i);
  };
  const handleCloseServiceCenter = () => {
    setShowLightboxServiceCenter(false);
    setSelectedImageServiceCenter(null);
  };
  const handlePrevRequestServiceCenter = (i, length) => e => {
    setSelectedImageServiceCenter((i - 1 + length) % length);
  };
  const handleNextRequestServiceCenter = (i, length) => e => {
    setSelectedImageServiceCenter((i + 1) % length);
  };

  const superstoreImages = data.allSanityGallery.nodes[0].images || [];
  const serviceCenterImages = data.allSanityGallery.nodes[1].images || [];

  return (
    <Layout>
      <div className="gallery-page">
        <section className="superstore-gallery">
          <div className="section-header container">
            <h1 className="section-title n-b-m n-t-m text-black">JP Superbikes Superstore - SW Florida</h1>
            <ul className="bread-crumbs">
              <li>
                <Link to="/">Home</Link>
              </li>
              <li className="current-bread-crumb">Gallery</li>
            </ul>
          </div>
          <hr />
          <div className="gallery-grid container">
            <ThumbGrid images={superstoreImages} handleOpen={handleOpen} />
            {showLightbox && selectedImage !== null && (
              <LightBox
                images={superstoreImages}
                handleClose={handleClose}
                handleNextRequest={handleNextRequest}
                handlePrevRequest={handlePrevRequest}
                selectedImage={selectedImage}
              />
            )}
          </div>
        </section>
        <section className="service-gallery">
          <div className="section-header container">
            <h1 className="section-title n-b-m n-t-m text-black">JP Superbikes Service Center - SW Florida</h1>
            <ul className="bread-crumbs">
              <li>
                <Link to="/">Home</Link>
              </li>
              <li className="current-bread-crumb">Gallery</li>
            </ul>
          </div>
          <hr />
          <div className="gallery-grid container">
            <ThumbGrid images={serviceCenterImages} handleOpen={handleOpenServiceCenter} />
            {showLightboxServiceCenter && selectedImageServiceCenter !== null && (
              <>
                <LightBox
                  images={serviceCenterImages}
                  handleClose={handleCloseServiceCenter}
                  handleNextRequest={handleNextRequestServiceCenter}
                  handlePrevRequest={handlePrevRequestServiceCenter}
                  selectedImage={selectedImageServiceCenter}
                />
              </>
            )}
          </div>
        </section>
      </div>
    </Layout>
  );
};

export default Gallery;
